import { batchNumberMain } from '../../models/accountancy/batchNumberModel';
import { itemMain } from '../../models/accountancy/item';
import * as FileSystem from 'expo-file-system';
import * as Sharing from "expo-sharing";

import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ApisauceInstance,
  create,
} from "apisauce"
import axios from "axios"
import Config from "../../config"
import { Platform } from 'react-native';
import { contactCategoryMain } from '../../models/accountancy/contactCategory';
import { contactMain } from '../../models/accountancy/contact';
import { transactionMain } from '../../models/accountancy/transaction';
import { accountMain } from '../../models/accountancy/account';
import { auditLogMain } from '../../models/accountancy/auditLogModel';
import { bankTransactionMain } from '../../models/accountancy/bankTransactionModel';
import { contactLedgerMain } from '../../models/accountancy/contactLedger';
import { batchNumberTrackingMain } from '../../models/accountancy/batchNumberTrackingModel';
import { expenseMain } from '../../models/accountancy/expenseModel';
import { fifoProductInMain } from '../../models/accountancy/fifoProductInModel';
import { fifoProductOutMain } from '../../models/accountancy/fifoProductOutModel';
import { journalMain } from '../../models/accountancy/journalModel';
import { manualJournalMain } from '../../models/accountancy/manualJournalModel';
import { serialNumberMain } from '../../models/accountancy/serialNumberModel';
import { serialNumberTrackingMain } from '../../models/accountancy/serialNumberTrackingModel';
import { taxGroupMain } from '../../models/accountancy/taxGroup';
import { taxMain } from '../../models/accountancy/taxModel';
import { tcsMain } from '../../models/accountancy/tcsModel';
import { transferMain } from '../../models/accountancy/transferModel';
import { attendanceRegisterMain } from '../../models/hrms/attendanceRegister';
import { employeeMain } from '../../models/hrms/employeeModel';
import { jobMain } from '../../models/hrms/jobModel';
import { leaveCategoryMain } from '../../models/hrms/leaveCategoryModel';
import { leaveRegisterMain } from '../../models/hrms/leaveRegisterModel';
import { leaveRequestMain } from '../../models/hrms/leaveRequestModel';
import { payrollDetailMain } from '../../models/hrms/payrollDetailModel';
import { payrollMain } from '../../models/hrms/payrollModel';
import { payrollSalaryItemMain } from '../../models/hrms/payrollSalaryItemModel';
import { payrollStatutoryComponentMain } from '../../models/hrms/payrollStatutoryComponentModel';
import { shiftMain } from '../../models/hrms/shiftModel';
import { shiftSchedulerMain } from '../../models/hrms/shiftSchedulerModel';
import { itemAdjustmentMain } from '../../models/accountancy/itemAdjustmentModel';
import { journalItemsMain } from '../../models/accountancy/journalItemsModel';
import { taxExemptionsMain } from '../../models/accountancy/taxExemptions';
import { shiftSchedulerDaysMain } from '../../models/hrms/shiftSchedulerDaysModel';
import { companyMain } from '../../models/hrms/companies';
import { departmentMain } from '../../models/hrms/departments';
import { designationMain } from '../../models/hrms/designationModel';
import { locationMain } from '../../models/hrms/locationModel';
import { teamMain } from '../../models/hrms/teamModel';
import { roleMain } from '../../models/hrms/roleModel';
import { holidayMain } from '../../models/hrms/holidayModel';
import { contactPaymentMain } from '../../models/accountancy/contactPaymentsModel';
import { projectMain } from '../../models/tasks/projects';
import { taskMain } from '../../models/tasks/tasks';
import { inquiryMain, leadMain } from '../../models/accountancy/lead';
import { workoutPlanMain } from '../../models/gym/workoutPlanModel';
import { dietPlanMain } from '../../models/gym/dietPlanModel';
import { equipmentMain } from '../../models/gym/equipmentModel';
import { exerciseMain } from '../../models/gym/exerciseModel';
import { gymClassMain } from '../../models/gym/gymClassModel';
import { gypProductsMain } from '../../models/gym/products';
import { gypPlansMain } from '../../models/gym/plans';
import { counterMain } from '../../models/accountancy/counterModel';
import { approvalRequestsMain } from '../../models/main/approvalRequests';
import { approvalRuleMain } from '../../models/main/approvalRulesModel';
import { approvalWorkFlowMain } from '../../models/main/approvalWorkFlow';
import { positionMain } from '../../models/hrms/positionModel';
import { brandMain } from '../../models/accountancy/brandModel';
import { itemCategoryMain } from '../../models/accountancy/itemCategory';
import { itemAttributeMain } from '../../models/accountancy/itemAttribute';
import { documentTemplateMain } from '../../models/main/documentTemplates';
import { notificationTemplateMain } from '../../models/main/NotificationTemplates';
import { unitMain } from '../../models/accountancy/unitModel';
import { reimbursementAdvancesMain } from '../../models/hrms/reimbursementAdvancesModel';
import { reimbursementClaimsMain } from '../../models/hrms/reimbursementClaimsModel';
import { reimbursementCategoryMain } from '../../models/hrms/reimbursementCategoryModel';
import { moneyTransferMain } from '../../models/accountancy/moneyTransferModel';
import { courseMain } from '../../models/lms/courses';
import { appointmentMain } from '../../models/doctors/appointments';
import { subscriptionMain } from '../../models/accountancy/subscriptionModel';
import { tableMain } from '../../models/restaurant/tables';
import { areaMain } from '../../models/restaurant/areas';
import { reservationMain } from '../../models/restaurant/reservations';
import { hotelRoomMain } from '../../models/hotel/hotelRooms';
import { hotelAreaMain } from '../../models/hotel/hotelAreas';
import { hotelReservationMain } from '../../models/hotel/hotelReservations';
import { webinarRegistrationMain } from '../../models/blog/webinarRegistrations';
import { webinarMain } from '../../models/blog/webinars';
import { leadMeetingMain } from '../../models/accountancy/leadMeetings';
import { broadcastNotificationMain } from '../../models/accountancy/broadcastNotifications';
import { productionWorkFlowMain } from '../../models/manufacture/productionWorkFlow';
import { productionOrderMain } from '../../models/manufacture/productionOrders';
import { videoMain } from '../../models/blog/videos';
import { manufactureMain } from '../../models/accountancy/manufactureModel';
import Dexie from 'dexie';
import { itemWastageMain } from '../../models/accountancy/itemWastageModel';

export const dixieDB = new Dexie('InkApps');

export const DEFAULT_API_CONFIG = {
  url: Config.API_URL,
  timeout: 1000000,
}

export const collectionsNames = [
  { name: "NotificationTemplates", data: notificationTemplateMain },
  { name: "DocumentTemplates", data: documentTemplateMain },
  { name: "Accounts", data: accountMain },
  { name: "AuditLogs", data: auditLogMain },
  { name: "BankTransactions", data: bankTransactionMain },
  { name: "Categories", data: contactCategoryMain },
  { name: "ContactCategories", data: contactCategoryMain },
  { name: "ItemCategories", data: itemCategoryMain },
  { name: "ItemAttributes", data: itemAttributeMain },
  { name: "Contacts", data: contactMain },
  { name: "Brands", data: brandMain },
  { name: "Units", data: unitMain },
  { name: "PaymentReceived", data: contactPaymentMain },
  { name: "MoneyTransfers", data: moneyTransferMain },
  { name: "PaymentMade", data: contactPaymentMain },
  { name: "ContactLedgers", data: contactLedgerMain },
  // { name: "CustomFields", data: customFieldMain },
  { name: "FifoProductIn", data: fifoProductInMain },
  { name: "FifoProductOut", data: fifoProductOutMain },
  { name: "Expenses", data: expenseMain },
  { name: "Items", data: itemMain },
  { name: "JournalItems", data: journalItemsMain },
  { name: "Journals", data: journalMain },
  { name: "ManualJournals", data: manualJournalMain },
  { name: "Transactions", data: transactionMain },
  { name: "Leads", data: leadMain },
  { name: "BroadcastNotifications", data: broadcastNotificationMain },
  { name: "LeadMeetings", data: leadMeetingMain },
  { name: "Counters", data: counterMain },
  { name: "Quotations", data: transactionMain },
  { name: "SalesOrders", data: transactionMain },
  { name: "DeliveryOrders", data: transactionMain },
  { name: "Invoices", data: transactionMain },
  { name: "SalesReturns", data: transactionMain },
  { name: "PurchaseOrders", data: transactionMain },
  { name: "Bills", data: transactionMain },
  { name: "PurchaseReturns", data: transactionMain },
  { name: "Users", data: transactionMain },
  { name: "BatchNumbers", data: batchNumberMain },
  { name: "Taxes", data: taxMain },
  { name: "TaxGroups", data: taxGroupMain },
  { name: "TaxExemptions", data: taxExemptionsMain },
  { name: "Tcs", data: tcsMain },
  { name: "AttendanceRegister", data: attendanceRegisterMain },
  { name: "Employees", data: employeeMain },
  { name: "Jobs", data: jobMain },
  { name: "LeaveCategories", data: leaveCategoryMain },
  { name: "Holidays", data: holidayMain },
  { name: "LeaveRegister", data: leaveRegisterMain },


  { name: "PayrollSalaryItems", data: payrollSalaryItemMain },
  { name: "PayrollDetails", data: payrollDetailMain },
  { name: "Payrolls", data: payrollMain },

  { name: "ReimbursementAdvances", data: reimbursementAdvancesMain },
  { name: "ReimbursementClaims", data: reimbursementClaimsMain },
  { name: "ReimbursementCategories", data: reimbursementCategoryMain },


  { name: "PayrollStatutoryComponents", data: payrollStatutoryComponentMain },
  { name: "Shifts", data: shiftMain },
  { name: "ShiftSchedulerDays", data: shiftSchedulerDaysMain },
  { name: "ShiftSchedulers", data: shiftSchedulerMain },
  { name: "LeaveRequests", data: leaveRequestMain },
  { name: "Transfers", data: transferMain },
  { name: "SerialNumbers", data: serialNumberMain },
  { name: "SerialNumberTracking", data: serialNumberTrackingMain },
  { name: "BatchNumberTracking", data: batchNumberTrackingMain },
  { name: "ItemAdjustments", data: itemAdjustmentMain },
  { name: "Companies", data: companyMain },
  { name: "Departments", data: departmentMain },
  { name: "Designations", data: designationMain },
  { name: "Locations", data: locationMain },
  { name: "Teams", data: teamMain },
  { name: "Roles", data: roleMain },
  { name: "Positions", data: positionMain },

  { name: "Courses", data: courseMain },
  { name: "Appointments", data: appointmentMain },
  { name: "Subscriptions", data: subscriptionMain },

  { name: "Projects", data: projectMain },
  { name: "Tasks", data: taskMain },

  { name: "WorkoutPlans", data: workoutPlanMain },
  { name: "DietPlans", data: dietPlanMain },
  { name: "Equipments", data: equipmentMain },
  { name: "Exercises", data: exerciseMain },
  { name: "GymClasses", data: gymClassMain },
  { name: "Products", data: gypProductsMain },
  { name: "Plans", data: gypPlansMain },

  { name: "ApprovalRequests", data: approvalRequestsMain },
  { name: "ApprovalRules", data: approvalRuleMain },
  { name: "ApprovalWorkflows", data: approvalWorkFlowMain },

  { name: "Tables", data: tableMain },
  { name: "Areas", data: areaMain },
  { name: "Reservations", data: reservationMain },

  { name: "HotelRooms", data: hotelRoomMain },
  { name: "HotelAreas", data: hotelAreaMain },
  { name: "HotelReservations", data: hotelReservationMain },

  { name: "Webinars", data: webinarMain },
  // { name: "Videos", data: videoMain },
  { name: "WebinarRegistrations", data: webinarRegistrationMain },

  { name: "ProductionWorkflows", data: productionWorkFlowMain },
  { name: "ProductionOrders", data: productionOrderMain },
  { name: "ManufactureItems", data: manufactureMain },

  { name: "ItemWastages", data: itemWastageMain },
];

// db.version(1).stores({
//   items: '_id',
//   transactions: '_id',
//   contacts: '_id',
// });

// db.version(1).stores({

const dbVersion = 1;
const dbStores = {};

collectionsNames.forEach((collection) => {
  dbStores[collection.name] = '_id';
});

dixieDB.version(dbVersion).stores(dbStores);

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  axios = axios.create({
    baseURL: Config.API_URL,
    timeout: 1000000,
    headers: {
      Accept: "application/json",
      organization: "",
      hosturl: Platform.OS == "web" ? null : Config.HOST_URL,
    },
  })

  setTokenToAPI(token, organization_id) {
    // this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    // this.axios.defaults.headers.common['organization'] = organization_id;

    if (Platform.OS === 'web') {
      this.axios = axios.create({
        baseURL: Config.API_URL,
        timeout: 1000000,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          organization: organization_id,
          hosturl: null,
        },
      })
    } else {
      this.axios = axios.create({
        baseURL: Config.API_URL,
        timeout: 1000000,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          organization: organization_id,
          hosturl: Config.HOST_URL,
        },
      })
    }
  }

  addErrorInterceptor(errorDisplayFunction) {
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        errorDisplayFunction(error)
        return Promise.reject(error)
      }
    )
  }

  updateLocationId(location_id) {
    this.axios.interceptors.request.use(function (config) {
      config.data.location_id = location_id;
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
  }

  getFieldValue(object, key) {
    if (!object) return;
    if (!key) return;

    return key.split('.').reduce((obj, prop) => obj && obj[prop], object);
  }

  formToObject(form) {
    let allFormFields = form || [];
    let data = {};

    for (let i = 0; i < allFormFields.length; i++) {
      let field = allFormFields[i];
      if (field.key?.includes('___')) {
        let keys = field.key.split('___');
        let key = keys[0];
        let nestedKey = keys[1];
        if (!data[key]) {
          data[key] = {};
        }
        data[key][nestedKey] = field.value;
        data[field.key] = field.value;
      } else if (field.key) {
        data[field.key] = field.value;
      }
    }

    return data;
  }

  objectToForm(object, form) {
    let new_form = [...form];
    for (let i = 0; i < new_form.length; i++) {
      let field = new_form[i];
      let key = field.key;

      if (key?.includes('___')) {
        let keys = key.replace('___', '.');

        let value = this.getFieldValue(object, keys);
        if (value !== undefined) {
          field.value = value;
        }
      }

      let value = this.getFieldValue(object, key);
      if (value !== undefined) {
        field.value = value;
      }
    }
    return new_form;
  }

  async login(data) {
    try {
      const response = await this.axios.post("/api/login", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in login" };
      }
    }
  }

  async generateTokenByUserId(data) {
    try {
      const response = await this.axios.post("/api/generate-token-by-user-id", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in login" };
      }
    }
  }

  async getPermissions(data) {
    try {
      const response = await this.axios.post("/api/getPermissions", {
        ...data
      });
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in login" };
      }
    }
  }

  async getCreateData(moduleName, extraData = {}) {
    try {
      const data = { ...extraData }

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + moduleName + "/getCreateData", data).catch((e) => {
      });
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in getting create data" };
      }
    }
  }

  async getPreferenceData(moduleName) {
    try {
      const data = {}

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + moduleName + "/getModulePreferences", data).catch((e) => {
      });
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in getting create data" };
      }
    }
  }

  async get(moduleName, data = {}) {
    try {

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + moduleName + "/get", data).catch((e) => {
      });

      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in getting" };
      }
    }
  }

  async getSingle(moduleName, data = {}) {
    try {
      const response = await this.axios.post("/api/" + moduleName + "/getSingle", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in getting" };
      }
    }
  }

  async customRoute(route, data = {}) {
    try {

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + route, data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in getting" };
      }
    }
  }

  async save(moduleName, data) {
    try {

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + moduleName + "/create", data)
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async savePreferenceData(moduleName, data) {
    try {

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      const response = await this.axios.post("/api/" + moduleName + "/saveModulePreferences", data)
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async update(moduleName, data, id) {
    try {

      if (!data.location_id) {
        const globalData = await AsyncStorage.getItem('globalData');
        data.location_id = JSON.parse(globalData || '{}').location_id;
      }

      data._id = id;
      const response = await this.axios.post("/api/" + moduleName + "/update", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async uploadFile(moduleName, data) {
    try {
      const response = await this.axios.post("/api/" + moduleName + "/uploadFile", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async delete(moduleName, id) {
    try {
      const data = { _id: id }
      const response = await this.axios.post("/api/" + moduleName + "/delete", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in deleting" };
      }
    }
  }

  async sync(moduleName, id) {
    try {
      const data = { _id: id }
      const response = await this.axios.post("/api/" + moduleName + "/sync", data);
      return response.data;
    } catch (e) {

    }
  }

  async saveColumns(moduleName, data) {
    try {
      const response = await this.axios.post("/api/" + moduleName + "/saveColumns", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async saveView(moduleName, data) {
    try {
      const response = await this.axios.post("/api/" + moduleName + "/saveView", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async deleteView(moduleName, data) {
    try {
      const response = await this.axios.post("/api/" + moduleName + "/deleteView", data);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.message || e?.response?.data?.errors || e?.response?.data?.errors) {
        return e.response.data;
      } else {
        return { success: false, message: "Error in saving" };
      }
    }
  }

  async getFormattedAddress(lat, long) {
    try {
      const response = await this.axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=15&addressdetails=1`);

      return response.data;
    } catch (e) {
      return { success: false, message: "Error in getting address" };
    }
  }

  getForm(moduleName) {
    const collection = collectionsNames.find(c => c.name === moduleName);
    if (!collection) throw new Error(moduleName + " Collection not found");

    return { ...collection.data };
  }

  async download(data, type) {
    if (Platform.OS === 'web') {
      const url = data;

      const fileName = url.split('/').pop();

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(url);
    } else {
      const url = data;
      const fileName = url.split('/').pop();
      const { uri } = await FileSystem.downloadAsync(data, FileSystem.documentDirectory + fileName);
      const isAvailable = await Sharing.isAvailableAsync();
      if (!isAvailable) {
        alert(`Uh oh, sharing isn't available on your platform`);
        return;
      }
      await Sharing.shareAsync(uri);
    }
  }

  decryptText(encryptedText) {
    const CryptoJS = require('crypto-js');

    const key = 'XXX12YZ1298';

    const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key);
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}

export const api = new Api()


export const UNIT_OF_MEASUREMENTS = [
  {
    "name": "Bag",
    "code": "BAG",
    "conversion_factor": 1
  },
  {
    "name": "Box",
    "code": "BOX",
    "conversion_factor": 1
  },
  {
    "name": "Bottles",
    "code": "BTL",
    "conversion_factor": 1
  },
  {
    "name": "Pieces",
    "code": "PCS",
    "conversion_factor": 1
  },
  {
    "name": "Units",
    "code": "UNT",
    "conversion_factor": 1
  },
  {
    "name": "Grams",
    "code": "GMS",
    "base_unit": "Mass",
    "conversion_factor": 1
  },
  {
    "name": "Kilograms",
    "code": "KGS",
    "base_unit": "Mass",
    "conversion_factor": 1000
  },
  {
    "name": "Milli Grams",
    "code": "MGS",
    "base_unit": "Mass",
    "conversion_factor": 0.001
  },
  {
    "name": "Kiloliter",
    "code": "KLR",
    "base_unit": "Volume",
    "conversion_factor": 1000000
  },
  {
    "name": "Liters",
    "code": "LTR",
    "base_unit": "Volume",
    "conversion_factor": 1000
  },
  {
    "name": "Milli Litre",
    "code": "MLT",
    "base_unit": "Volume",
    "conversion_factor": 1
  },
  {
    "name": "Centimeter",
    "code": "CMS",
    "base_unit": "Length",
    "conversion_factor": 1
  },
  {
    "name": "Feet",
    "code": "FTS",
    "base_unit": "Length",
    "conversion_factor": 30.48
  },
  {
    "name": "Kilometers",
    "code": "KME",
    "base_unit": "Length",
    "conversion_factor": 100000
  },
  {
    "name": "Inches",
    "code": "INC",
    "base_unit": "Length",
    "conversion_factor": 2.54
  },
  {
    "name": "Meter",
    "code": "MTR",
    "base_unit": "Length",
    "conversion_factor": 100
  },
  {
    "name": "Square Feet",
    "code": "SQF",
    "base_unit": "Area",
    "conversion_factor": 929.0304
  },
  {
    "name": "Square Meter",
    "code": "SQM",
    "base_unit": "Area",
    "conversion_factor": 10000
  }
];

// {
//   "name": "Bunches",
//   "code": "BUN"
// },
// {
//   "name": "Cubic Meter",
//   "code": "CBM",
//   "base_unit": "Volume"
// },
// {
//   "name": "Cubic Centimeter",
//   "code": "CCM",
//   "base_unit": "Volume"
// },
// {
//   "name": "Cubic Inches",
//   "code": "CIN",
//   "base_unit": "Volume"
// },
// {
//   "name": "Cubic Meters",
//   "code": "CQM",
//   "base_unit": "Volume"
// },
// {
//   "name": "Carton",
//   "code": "CTN"
// },
// {
//   "name": "Dozen",
//   "code": "DOZ"
// },
// {
//   "name": "Drum",
//   "code": "DRM"
// },
// {
//   "name": "Great Gross",
//   "code": "GGR"
// },
// {
//   "name": "Gross",
//   "code": "GRS"
// },
// {
//   "name": "Gross Yards",
//   "code": "GYD"
// },
// {
//   "name": "Hanks",
//   "code": "HKS"
// },
// {
//   "name": "Pounds",
//   "code": "LBS",
//   "base_unit": "Mass"
// },
// {
//   "name": "Lots",
//   "code": "LOT"
// },
// {
//   "name": "Metric Ton",
//   "code": "MTS",
//   "base_unit": "Mass"
// },
// {
//   "name": "Numbers",
//   "code": "NOS"
// },
// {
//   "name": "Odds",
//   "code": "ODD"
// },
// {
//   "name": "Packs",
//   "code": "PAC"
// },
// {
//   "name": "Pairs",
//   "code": "PRS"
// },
// {
//   "name": "Quintal",
//   "code": "QTL",
//   "base_unit": "Mass"
// },
// {
//   "name": "Rolls",
//   "code": "ROL"
// },
// {
//   "name": "Sets",
//   "code": "SET"
// },
// {
//   "name": "Sheets",
//   "code": "SHT"
// },
// {
//   "name": "Square Inches",
//   "code": "SQI",
//   "base_unit": "Area"
// },
// {
//   "name": "Tola",
//   "code": "TOL",
//   "base_unit": "Mass"
// },

// {
//   "name": "Decameter Square",
//   "code": "SDM",
//   "base_unit": "Area"
// },

// {
//   "name": "Bags",
//   "code": "BGS"
// },
// {
//   "name": "Buckles",
//   "code": "BKL"
// },
// {
//   "name": "Billion Of Units",
//   "code": "BOU"
// },
// {
//   "name": "Vials",
//   "code": "VLS"
// },
// {
//   "name": "Yards",
//   "code": "YDS",
//   "base_unit": "Length"
// },
// {
//   "name": "Cans",
//   "code": "CAN"
// },
// {
//   "name": "Bundles",
//   "code": "BDL"
// },
// {
//   "name": "Bale",
//   "code": "BAL"
// },
// {
//   "name": "Ten Gross",
//   "code": "TGM"
// }
// {
//   "name": "Great Britain Ton",
//   "code": "TON",
//   "base_unit": "Mass"
// },
// {
//   "name": "Tubes",
//   "code": "TUB"
// },
// {
//   "name": "Us Gallons",
//   "code": "UGS",
//   "base_unit": "Volume"
// },
// {
//   "name": "Tablets",
//     "code": "TBS"
// },
// {
//   "name": "Thousands",
//     "code": "THD"
// },
// {
//   "name": "Square Yards",
//   "code": "SQY",
//   "base_unit": "Area"
// },