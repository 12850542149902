import React, { useEffect } from "react";
import { View, Card, Text, ExpandableSection } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";

const permissionCategories = {
  Accounts: 'ACCOUNTS',
  TaxGroups: 'ACCOUNTS',
  Taxes: 'ACCOUNTS',
  Tcs: 'ACCOUNTS',
  AccountingReports: 'ACCOUNTS',
  PaymentReceived: 'FINANCE',
  MoneyTransfers: 'FINANCE',
  PaymentMade: 'FINANCE',
  Items: 'INVENTORY',
  Transfers: 'INVENTORY',
  ItemAdjustments: 'INVENTORY',
  ItemWastages: 'INVENTORY',
  ManufactureItems: 'INVENTORY',
  BarcodePrinting: 'INVENTORY',
  Contacts: 'SALES',
  Leads: 'SALES',
  LeadMeetings: 'SALES',
  BroadcastNotifications: 'SALES',
  Quotations: 'SALES',
  SalesOrders: 'SALES',
  DeliveryOrders: 'SALES',
  Invoices: 'SALES',
  SalesReturns: 'SALES',
  POS: 'SALES',
  Kitchen: 'SALES',
  table: 'SALES',
  rooms: 'SALES',
  Reservations: 'SALES',
  HotelReservations: 'SALES',
  PurchaseOrders: 'PURCHASE',
  Bills: 'PURCHASE',
  PurchaseReturns: 'PURCHASE',
  CollectionSummaryCard: 'HOMECARDS',
  GlobalCollectionSummaryCard: 'HOMECARDS',
  BalanceCard: 'HOMECARDS',
  CheckInCard: 'HOMECARDS',

  Employees: 'MASTERS',
  Jobs: 'HRMS',
  LeaveCategories: 'HRMS',
  ReimbursementAdvances: 'HRMS',
  ReimbursementCategories: 'HRMS',
  ReimbursementClaims: 'HRMS',
  PayrollSalaryItems: 'HRMS',
  PayrollDetails: 'HRMS',
  Payrolls: 'HRMS',
  PayrollStatutoryComponents: 'HRMS',
  Shifts: 'MASTERS',
  ShiftSchedulerDays: 'HRMS',
  LeaveRequests: 'HRMS',
  //Transfers: 'HRMS',
  Courses: 'HRMS',
  Locations: 'MASTERS',
  Companies: 'MASTERS',
  Departments: 'MASTERS',
  Designations: 'MASTERS',
  Teams: 'MASTERS',
  Roles: 'MASTERS',
  Positions: 'MASTERS',
  Holidays: 'HRMS',
  "report/AttendanceByDate": 'HRMS',
  "report/AttendanceByEmployee": 'HRMS',
  AttendanceByEmployee: 'HRMS',
  AttendanceByDate: 'HRMS',
  ApprovalRequests: 'HRMS',
  ApprovalRules: 'HRMS',
  ApprovalWorkflows: 'HRMS',
};


const defaultPermissions = {
  NotificationTemplates: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  DocumentTemplates: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Webinars: {
    view: true,
    create: true,
    update: true,
    delete: true
  },

  WebinarRegistrations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },

  Accounts: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ProductionWorkflows: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ProductionOrders: {
    view: true,
    create: true,
    update: true,
    delete: true
  },

  // BankTransactions: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Categories: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  Contacts: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // Appointments: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Subscriptions: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  PaymentReceived: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  MoneyTransfers: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PaymentMade: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // Expenses: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // ContactLedgers: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // CustomFields: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Assets: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  Items: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // ManualJournals: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  Leads: {
    admin: true,
    view: true,
    create: true,
    update: true,
    delete: true
  },
  LeadMeetings: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  BroadcastNotifications: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Quotations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  SalesOrders: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  DeliveryOrders: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Invoices: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  SalesReturns: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PurchaseOrders: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Bills: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PurchaseReturns: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Locations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Taxes: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  TaxGroups: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Tcs: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // "report/AttendanceByDate": {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // "report/AttendanceByEmployee": {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  Employees: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Jobs: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  LeaveCategories: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ReimbursementAdvances: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ReimbursementCategories: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ReimbursementClaims: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PayrollSalaryItems: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PayrollDetails: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Payrolls: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  PayrollStatutoryComponents: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Shifts: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // ShiftSchedulerDays: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  LeaveRequests: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Transfers: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ItemAdjustments: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ManufactureItems: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ItemWastages: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // Projects: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Tasks: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Units: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // Brands: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  Companies: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Departments: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Designations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Teams: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Roles: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Positions: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  Holidays: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // VisibleFields: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  // LeaveBalanceOverrides: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  ApprovalRequests: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ApprovalRules: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  ApprovalWorkflows: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // Courses: {
  //   view: true,
  //   create: true,
  //   update: true,
  //   delete: true
  // },
  AccountingReports: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  // ItemTracing: {
  //   view: true,
  //   create: true,
  // },
  BarcodePrinting: {
    view: true,
    // create: true,
  },
  table: {
    view: true,
    // create: true,
  },
  Reservations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  HotelReservations: {
    view: true,
    create: true,
    update: true,
    delete: true
  },
  rooms: {
    view: true,
    // create: true,
  },
  POS: {
    view: true,
    // create: true,
  },
  Kitchen: {
    view: true,
  },
  AttendanceByEmployee: {
    view: true,
  },
  AttendanceByDate: {
    view: true,
  },
  // PaymentCollectionByEmployee: {
  //   view: true,
  // },
  // UnsoldSerialNumbersByLocation: {
  //   view: true,
  // },
  // PendingDeliveriesByLocation: {
  //   view: true,
  // },
  // CollectionSummaryCard: {
  //   view: true,
  // },
  // GlobalCollectionSummaryCard: {
  //   view: true,
  // },
  BalanceCard: {
    view: true,
  },
  CheckInCard: {
    view: true,
  }
};

export const rolefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const PermissionsComponent = ({ field, useForm, tKey, tIndex }) => {
    let permissions = useForm((state) => state.formObject['permissions']) || defaultPermissions;
    let updateVisibility = useForm((state) => state.formObject['updateVisibility']) || 0;
    const setFormObject = useForm((state) => state.setFormObject);


    permissions = { ...defaultPermissions, ...permissions };

    for (let key in permissions) {
      permissions[key] = { ...defaultPermissions[key], ...permissions[key] };
    }

    const permissionsByCategory = {};

    Object.keys(permissions).forEach((key) => {
      const category = permissionCategories[key] || 'OTHERS';

      if (category == 'OTHERS') {
        console.log('No category found for permission: ', key);
        return;
      }

      if (!permissionsByCategory[category]) {
        permissionsByCategory[category] = {};
      }
      permissionsByCategory[category][key] = permissions[key];
    });

    const formattedText = (text) => {
      return text.split('_').map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    const formattedTitle = (text) => {
      text = text.replace('report/', '');

      return text.split(/(?=[A-Z])/).map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    const [expandedSection, setExpandedSection] = React.useState(null);

    return <View style={{ width: '100%' }} key={updateVisibility}>
      {isEdit ? <View padding-10 marginB-10 style={{
        backgroundColor: '#ffe9e9',
        borderRadius: 5,
        borderColor: '#ff7272',
        borderWidth: 2,
        borderStyle: 'dashed',
      }}>
        <Text h1>IMPORTANT NOTE</Text>
        <Text marginT-3 dark10>
          Please note that if you deselect any employee it WON'T REMOVE the role from the employee. You can ONLY ASSIGN roles to employees from here. To remove a role from an employee, please edit the employee or edit anyother role and assign that role to the employee. {'\n'}{'\n'}

          We are doing this because each employee must have atleast one role assigned to them. If we allow you to remove all roles from an employee, it will cause issues in the system.
        </Text>
      </View> : null}

      <View row marginB-10>
        <View marginR-10>
          <SecondaryButton label={"Select All"} onPress={() => {
            const newPermissions = { ...permissions };
            Object.keys(newPermissions).forEach((key) => {
              newPermissions[key] = { ...newPermissions[key] };
              Object.keys(newPermissions[key]).forEach((subKey) => {
                newPermissions[key][subKey] = true;
              });
            });
            setFormObject({
              permissions: newPermissions
            });
          }} />
        </View>
        <View>
          <SecondaryButton label={"Deselect All"} onPress={() => {
            const newPermissions = { ...permissions };
            Object.keys(newPermissions).forEach((key) => {
              newPermissions[key] = { ...newPermissions[key] };
              Object.keys(newPermissions[key]).forEach((subKey) => {
                newPermissions[key][subKey] = false;
              });
            });
            setFormObject({
              permissions: newPermissions
            });
          }} />
        </View>
      </View>

      <View style={{ width: '100%' }}>
        {Object.keys(permissionsByCategory).map((category, index) => (
          <ExpandableSection
            key={index.toString()}
            sectionHeader={
              <View flex centerV marginB-0 style={{
                width: '100%',
                marginTop: Platform.OS != 'web' ? 0 : 10,
              }}>
                <Text h1 style={{
                  marginTop: Platform.OS != 'web' ? 0 : 10,
                }}>{category}</Text>
                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                  <View
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderColor: '#d9d9d9',
                      margin: -2,
                      marginTop: 10,
                    }}>
                    <View style={{ height: 20, width: 200 }} />
                  </View>
                </View> : <View style={{
                  borderBottomColor: '#d9d9d9',
                  borderBottomWidth: 1,
                  width: '100%',
                  marginBottom: 0,
                  marginTop: 10,
                  borderStyle: 'dashed',
                }} />}
              </View>
            }
            expanded={expandedSection === index}
            onPress={() => {
              if (expandedSection === index) {
                setExpandedSection(null);
              } else {
                setExpandedSection(index);
              }
            }}
          >
            <View row marginT-10 marginB-10>
              <View marginR-10>
                <SecondaryButton label={"Select All"} onPress={() => {
                  const newPermissions = { ...permissions };
                  Object.keys(permissionsByCategory[category]).forEach((key) => {
                    newPermissions[key] = { ...newPermissions[key] };
                    Object.keys(newPermissions[key]).forEach((subKey) => {
                      newPermissions[key][subKey] = true;
                    });
                  });
                  setFormObject({
                    permissions: newPermissions
                  });
                }} />
              </View>
              <View>
                <SecondaryButton label={"Deselect All"} onPress={() => {
                  const newPermissions = { ...permissions };
                  Object.keys(permissionsByCategory[category]).forEach((key) => {
                    newPermissions[key] = { ...newPermissions[key] };
                    Object.keys(newPermissions[key]).forEach((subKey) => {
                      newPermissions[key][subKey] = false;
                    });
                  });
                  setFormObject({
                    permissions: newPermissions
                  });
                }} />
              </View>
            </View>

            <Row style={{ width: '100%' }}>
              {Object.keys(permissionsByCategory[category]).map((key, index) => (
                <Col width="20%" key={index}>
                  <View key={index} style={{ width: '100%' }}>

                    <View row paddingB-3 marginB-8 style={{ width: '100%', borderBottomColor: '#d9d9d9', borderBottomWidth: 1, borderStyle: 'dashed' }}>
                      <View row marginR-5>
                        {/* all checkbox so that all sub permissions are checked */}
                        <CustomCheckbox
                          value={Object.keys(permissions[key]).every((subKey) => permissions[key][subKey])}
                          indeterminate={Object.keys(permissions[key]).some((subKey) => permissions[key][subKey])}
                          onChange={(value) => {
                            const newPermissions = { ...permissions };
                            newPermissions[key] = { ...newPermissions[key] };
                            Object.keys(newPermissions[key]).forEach((subKey) => {
                              newPermissions[key][subKey] = value;
                            });
                            setFormObject({
                              permissions: newPermissions
                            });
                          }} />
                      </View>
                      <Text h1>{formattedTitle(key)}</Text>
                    </View>

                    {Object.keys(permissions[key]).map((subKey, subindex) => {
                      return <View row key={subindex}>
                        <View marginT-3>
                          <CustomCheckbox value={permissions[key][subKey]} onChange={(value) => {
                            const newPermissions = { ...permissions };
                            newPermissions[key] = { ...newPermissions[key], [subKey]: value };
                            setFormObject({
                              permissions: newPermissions
                            });
                          }} />
                        </View>
                        <Text>{formattedText(subKey)}</Text>
                      </View>
                    })}
                  </View>
                </Col>
              ))}
            </Row>
          </ExpandableSection>
        ))}
      </View>
    </View >
  }

  const formFields = [
    {
      type: 'divider',
      heading: 'Role Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter role Name',
      visible: true,
      width: '25%',
      required: true,
    },

    {
      label: 'Employees',
      key: 'employee_ids',
      type: 'select',
      placeholder: 'Select the employees',
      visible: true,
      width: '25%',
      options: response.Employees || [],
      multiple: true,
    },

    {
      label: 'Permisssion Template',
      key: 'permission_template',
      type: 'select',
      placeholder: 'Select the permission template',
      visible: true,
      width: '25%',
      options: [
        { label: "Super Admin", value: "Super Admin" },
        { label: "Admin", value: "Admin" },
        { label: "Manager", value: "Manager" },
        { label: "Waiter", value: "Waiter" },
        { label: "Chef", value: "Chef" },
        // { label: "Accountant", value: "Accountant" },
        // { label: "HR Manager", value: "HR Manager" },
      ],
      onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
        console.log('value', value);
        if (value == 'Chef') {
          const newPermissions = { ...getObj()['permissions'] || defaultPermissions };
          console.log('newPermissions', newPermissions);
          for (let key in newPermissions) {
            newPermissions[key] = { ...newPermissions[key] };
            for (let subKey in newPermissions[key]) {
              newPermissions[key][subKey] = false;
            }
          }
          newPermissions.Kitchen = { view: true };
          newPermissions.CheckInCard = { view: true };

          console.log('newPermissions', newPermissions);
          setObj({
            permissions: newPermissions,
            updateVisibility: Math.random()
          });
        } else if (value == 'Waiter') {
          const newPermissions = { ...getObj()['permissions'] || defaultPermissions };
          for (let key in newPermissions) {
            newPermissions[key] = { ...newPermissions[key] };
            for (let subKey in newPermissions[key]) {
              newPermissions[key][subKey] = false;
            }
          }
          newPermissions.table = { view: true };
          newPermissions.CheckInCard = { view: true };
          setObj({
            permissions: newPermissions,
            updateVisibility: Math.random()
          });
        } else if (value == 'Manager') {
          const newPermissions = { ...getObj()['permissions'] || defaultPermissions };
          for (let key in newPermissions) {
            newPermissions[key] = { ...newPermissions[key] };
            for (let subKey in newPermissions[key]) {
              newPermissions[key][subKey] = false;
            }
          }
          newPermissions.table = { view: true };
          newPermissions.Reservations = { view: true, create: true, update: true, delete: true };
          newPermissions.POS = { view: true };
          newPermissions.Contacts = { view: true, create: true, update: true, delete: true };
          newPermissions.Invoices = { view: true, create: true, update: true, delete: true };
          newPermissions.SalesReturns = { view: true, create: true, update: true, delete: true };
          newPermissions.Bills = { view: true, create: true, update: true, delete: true };
          newPermissions.PurchaseReturns = { view: true, create: true, update: true, delete: true };
          newPermissions.PaymentReceived = { view: true, create: true, update: true, delete: true };
          newPermissions.MoneyTransfers = { view: true, create: true, update: true, delete: true };
          newPermissions.PaymentMade = { view: true, create: true, update: true, delete: true };
          newPermissions.Items = { view: true, create: true, update: true, delete: true };
          newPermissions.ItemAdjustments = { view: true, create: true, update: true, delete: true };
          newPermissions.BarcodePrinting = { view: true, create: true, update: true, delete: true };
          newPermissions.AttendanceByDate = { view: true };
          newPermissions.AttendanceByEmployee = { view: true };
          newPermissions.CheckInCard = { view: true };
          setObj({
            permissions: newPermissions,
            updateVisibility: Math.random()
          });
        } else if (value == 'Admin') {
          const newPermissions = { ...getObj()['permissions'] || defaultPermissions };
          for (let key in newPermissions) {
            newPermissions[key] = { ...newPermissions[key] };
            for (let subKey in newPermissions[key]) {
              newPermissions[key][subKey] = false;
            }
          }
          newPermissions.AttendanceByDate = { view: true };
          newPermissions.AttendanceByEmployee = { view: true };
          newPermissions.AccountingReports = { view: true };
          newPermissions.Items = { view: true };
          newPermissions.Employees = { view: true };
          setObj({
            permissions: newPermissions,
            updateVisibility: Math.random()
          });
        } else if (value == 'Super Admin') {
          const newPermissions = { ...getObj()['permissions'] || defaultPermissions };
          for (let key in newPermissions) {
            newPermissions[key] = { ...newPermissions[key] };
            for (let subKey in newPermissions[key]) {
              newPermissions[key][subKey] = true;
            }
          }
          setObj({
            permissions: newPermissions,
            updateVisibility: Math.random()
          });
        }
      }
    },

    {
      width: '100%',
      key: 'permissions',
      type: 'custom',
      visible: true,
      component: PermissionsComponent,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const roleMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const roleMain = {
  fields: rolefields,
  mobileCard: null
}