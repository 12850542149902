import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, TextInput, TouchableOpacity, DeviceEventEmitter, Dimensions } from "react-native"

import { api } from "../../services/api"
import { colors, spacing } from "../../theme"
import { View, Text, Card, TextField as TF, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, ListItem, Typography } from 'react-native-ui-lib';
import DynamicForm, { CustomDropDown, CustomModal, CustomNumberInput, CustomTextField, DangerButton, SecondaryButton } from "../DynamicForm"
import DynamicTable from "../DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "./LayoutScreen"
import { WebView } from 'react-native-webview';
import { create } from 'zustand'
import { Icon } from "react-native-eva-icons"
import pluralize from 'pluralize';
import { App, Dropdown } from 'antd';
import { GlobalModalStore } from "../state/ModalStore"
import { GlobalFormStore } from "../state/FormStore"
import { GlobalMasterStore } from "../state/PermissionStore"
import { CustomModalContext } from "../CustomModalProvider"
import { useCreateDataObjectForPurchase, useCreateDataObjectForPurchaseReturn, useCreateDataObjectForSales, useCreateDataObjectForSalesReturn } from "../../screens/MainScreens/Custom/Internal_Pages/accounting/PosScreen"
import { GlobalLoaderStore } from "../state/GlobalLoaderStore"


export const CommentsSection = () => {
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState('');

  const handleCommentSubmit = () => {
    if (newComment) {
      setComments([
        ...comments,
        { user: 'Current User', message: newComment, id: comments.length + 1 },
      ]);
      setNewComment('');
    }
  };

  const renderCommentItem = ({ item }) => (<View row top>
    <Avatar
      size={30}
      label={item.user.charAt(0)}
      // backgroundColor={Colors.violet30}
      containerStyle={{ marginRight: 12 }}
      // labelColor={Colors.white}
      useAutoColors
    />
    <Card padding-20 flex>
      <View row left centerV>
        <View marginR-5>
          <Text style={{ fontWeight: 'bold', color: '#343434' }}>
            {item.user}
          </Text>
        </View>

        <Text style={{ color: '#34343466', fontSize: 12 }}>
          Commented
        </Text>
      </View>
      <View>
        <Text>{item.message}</Text>
      </View>

    </Card>

  </View>
    // <ListItem
    //   activeBackgroundColor={Colors.dark60}
    //   activeOpacity={0.3}
    //   containerStyle={{ paddingVertical: 12 }}
    //   onPress={() => {}}
    // >
    //   <Avatar
    //     label={item.user.charAt(0)}
    //     backgroundColor={Colors.violet30}
    //     containerStyle={{ marginRight: 12 }}
    //     labelStyle={{ fontWeight: 'bold' }}
    //   />
    //   <ListItem.Content>
    //     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    //       <Text style={{ flex: 1 }}>{item.user}</Text>
    //       <Text style={{ color: Colors.dark40 }}></Text>
    //     </View>
    //     <Text style={{ ...Typography.text70, marginTop: 4 }}>{item.message}</Text>
    //   </ListItem.Content>
    // </ListItem>
  );

  const formatDate = (date) => {
    // Format the date as per your requirement
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={comments}
        renderItem={renderCommentItem}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={{ flexGrow: 1, padding: 16 }}
      />
      <View style={{ padding: 16, borderTopWidth: 1, borderTopColor: Colors.dark70 }}>
        <View style={{ flexDirection: 'row', marginBottom: 8 }}>
          <Avatar
            size={30}
            label="U"
            backgroundColor={Colors.violet30}
            containerStyle={{ marginRight: 12 }}
          />
          <TextInput
            placeholder="Write a comment..."
            value={newComment}
            onChangeText={setNewComment}
            style={{ flex: 1, paddingVertical: 8, paddingHorizontal: 12, borderRadius: 8, backgroundColor: Colors.dark80, color: Colors.white }}
            multiline
          />
        </View>
        <TouchableOpacity
          style={{ backgroundColor: Colors.violet30, borderRadius: 8, paddingVertical: 12, alignItems: 'center' }}
          onPress={handleCommentSubmit}
        >
          <Text style={{ color: Colors.white, fontWeight: 'bold' }}>Post Comment</Text>
        </TouchableOpacity>
      </View>

      {/* <WebView style={{ flex: 4, height: 250 }} source={{ uri: 'https://64674c3127381e6fccc0bd9a--lively-dieffenbachia-14bb39.netlify.app' }} /> */}
    </View>
  );
};


const TextField = React.memo(({ value, onChange }) => {
  return <CustomTextField
    value={value}
    onChange={(value) => {
      onChange(value)
    }}
  />
});

const FormScreen = observer(function FormScreen(props) {
  const { route } = props;

  const getOpenForms = GlobalFormStore((state) => state.openForms);
  const setOpenForm = GlobalFormStore((state) => state.setOpenForm);
  const deleteForm = GlobalFormStore((state) => state.deleteForm);

  const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
  const globalData = GlobalMasterStore((state) => state.globalData);
  const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

  const createDataObjectForSales = useCreateDataObjectForSales((state) => state.createDataObject);
  const setCreateDataObjectForSales = useCreateDataObjectForSales((state) => state.setCreateDataObject);
  const lastCreateDataObjectDateSales = useCreateDataObjectForSales((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDateSales = useCreateDataObjectForSales((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForSalesReturn = useCreateDataObjectForSalesReturn((state) => state.createDataObject);
  const setCreateDataObjectForSalesReturn = useCreateDataObjectForSalesReturn((state) => state.setCreateDataObject);
  const lastCreateDataObjectDateSalesReturn = useCreateDataObjectForSalesReturn((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDateSalesReturn = useCreateDataObjectForSalesReturn((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForPurchase = useCreateDataObjectForPurchase((state) => state.createDataObject);
  const setCreateDataObjectForPurchase = useCreateDataObjectForPurchase((state) => state.setCreateDataObject);
  const lastCreateDataObjectDatePurchase = useCreateDataObjectForPurchase((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDatePurchase = useCreateDataObjectForPurchase((state) => state.setLastCreateDataObjectDate);

  const createDataObjectForPurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.createDataObject);
  const setCreateDataObjectForPurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.setCreateDataObject);
  const lastCreateDataObjectDatePurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.lastCreateDataObjectDate);
  const setLastCreateDataObjectDatePurchaseReturn = useCreateDataObjectForPurchaseReturn((state) => state.setLastCreateDataObjectDate);

  const permissions = GlobalMasterStore((state) => state.permissions);

  let queryParams = {};

  let params = route?.params ? route?.params : useParams();

  const { openCustomModal } = useContext(CustomModalContext);

  const { message, modal } = App.useApp();

  if (Platform.OS === 'web') {
    const fullUrl = new URL(window.location.href).search;
    const queryStringStart = fullUrl.indexOf('?');
    const queryString = fullUrl.substring(queryStringStart);

    const queries = queryString.substring(1);

    queries.split('&').forEach(pair => {
      const [key, value] = pair.split('=');
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    if (!params.ignoreQueryParams) {
      params = { ...params, ...queryParams }
    }
  }

  const [locationForm, setLocationForm] = useState([]);
  const [moduleObj, setModuleObj] = useState({});
  const moduleObjRef = useRef(moduleObj);
  const [modulePrefObj, setModulePreObj] = useState({});
  const modulePrefObjRef = useRef(modulePrefObj);
  const [saving, setSaving] = useState(false);
  const moduleName = params?.moduleName ? params?.moduleName : 'Items';
  const [formUID, setFormUID] = useState(Math.floor(1000 + Math.random() * 9000));
  const [showPreferences, setShowPreferences] = useState(false);
  const [syncClickCount, setSyncClickCount] = useState(0);
  const [reloadUI, setReloadUI] = useState(0);
  const setGlobalLoading = GlobalLoaderStore((state) => state.setLoading);

  const { fields, dataTabs, preferencesFields, ExtraSaveComponent, customActions, CustomViewHeader, customHeaderHeight } = api.getForm(moduleName);

  const [tabs, setTabs] = useState([
    ...(dataTabs ? dataTabs : []),
    // {
    //   label: 'Comments',
    //   icon: 'message-square-outline'
    // }, {
    //   label: 'Documents',
    //   icon: 'attach-outline'
    // }
  ]);

  const [activeTab, setActiveTab] = useState({
    label: 'Details',
  });

  const nativeRoute = useNavigationState(state => state);

  let navigation = useNavigation();
  let navigate = null;

  if (Platform.OS === 'web') {
    navigate = useNavigate();
  }

  const navigationFn = (path, params = null) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + "redirect");
      setTimeout(() => {
        navigate("/" + path + paramsString);
      }, 0);
    } else {
      if (path.includes("report/")) {
        navigation.push("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  const goBack = () => {
    if (Platform.OS === 'web') {
      setOpenModal({ [moduleName]: null })
    } else {

      navigation.pop();
    }
  }

  const getOpenFormsRef = useRef(getOpenForms);

  useEffect(() => {
    getOpenFormsRef.current = getOpenForms;
  }, [getOpenForms]);

  const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
    DeviceEventEmitter.addListener("reloadData" + formUID, (event) => {
      if (!event) {
        DeviceEventEmitter.removeAllListeners("reloadData" + formUID);
        // if (Platform.OS == 'web') {
        //   setOpenModal({ [moduleName]: null })
        // } else {
        //   
        //   navigation.pop();
        // }
        return;
      }

      if (event?.deleted) {
        DeviceEventEmitter.removeAllListeners("reloadData" + formUID);
        let object = { ...moduleObjRef.current };

        if (event?.id && field_key) {
          if (array) {
            const oldValues = object[field_key] ? object[field_key] : [];
            object[field_key] = oldValues.filter((id) => id != event?.id);
          } else {
            object[field_key] = null;
          }
        }

        getModuleCreateData(object);

        if (Platform.OS == 'web') {
          setOpenModal({
            [newModule]: null
          })
        } else {
          goBack();
        }
        return;
      }

      let object = { ...moduleObjRef.current };

      if (field_key) {
        if (array) {
          const oldValues = object[field_key] ? object[field_key] : [];
          object[field_key] = [...new Set([...oldValues, event._id])]
        } else {
          object[field_key] = event._id;
        }
      }

      getModuleCreateData(object);

      // if (Platform.OS == 'web') {
      //   setOpenModal({
      //     [newModule]: null
      //   })
      // } else {
      //   goBack();
      // }

      DeviceEventEmitter.removeAllListeners("reloadData" + formUID);
    })

    if (Platform.OS == 'web') {
      let data = {
        [newModule]: {
          isVisible: true,
          moduleName: [newModule],
          viewOnly: false,
          afterSaveEvent: 'reloadData' + formUID,
          ignoreQueryParams: true,
          ...(params ? params : {})
        }
      };

      setOpenModal(data)
    } else {
      navigationFn('New', {
        moduleName: newModule,
        afterSaveEvent: 'reloadData' + formUID,
        ...(params ? params : {})
      })
    }
  }

  const getModuleCreateData = async (values = null, forceReload) => {
    const isEdit = params?.isEdit == "true" || params?.isEdit == true;

    let response = null;
    setGlobalLoading(true)

    if (["Invoices", "Quotations", "SalesOrders", "DeliveryOrders"].includes(moduleName)) {
      if (lastCreateDataObjectDateSales) {
        response = createDataObjectForSales;
      } else {
        response = await processCreateData(moduleName)
      }
    } else if (["SalesReturns"].includes(moduleName)) {
      if (lastCreateDataObjectDateSalesReturn) {
        response = createDataObjectForSalesReturn;
      } else {
        response = await processCreateData(moduleName)
      }
    } else if (["PurchaseOrders", "Bills"].includes(moduleName)) {
      if (lastCreateDataObjectDatePurchase) {
        response = createDataObjectForPurchase;
      } else {
        response = await processCreateData(moduleName)
      }
    } else if (["PurchaseReturns"].includes(moduleName)) {
      if (lastCreateDataObjectDatePurchaseReturn) {
        response = createDataObjectForPurchaseReturn;
      } else {
        response = await processCreateData(moduleName)
      }
    } else {
      response = await api.getCreateData(moduleName, params);
    }

    if (forceReload) {
      if (["Invoices", "Quotations", "SalesOrders", "DeliveryOrders", "SalesReturns", "PurchaseOrders", "Bills", "PurchaseReturns"].includes(moduleName)) {
        response = await processCreateData(moduleName)
      }
    }

    if (params?.id) {
      const id = params?.id;
      const cloningModuleName = params?.cloningModuleName ? params?.cloningModuleName : moduleName;

      const { data } = await api.getSingle(cloningModuleName, {
        _id: id,
        converting: cloningModuleName == moduleName ? false : true,
        isClone: params?.isClone ? true : false,
        isEdit: params?.isEdit ? true : false,
        platform: Platform.OS
      });

      let form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data, isEdit, globalData, setGlobalData, message, navigationFn, permissions));

      if (values) {
        form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data, isEdit, globalData, setGlobalData, message, navigationFn, permissions));
      }

      const object = api.formToObject(form);
      const finalObj = { ...data, ...object };

      setOpenForm({
        [formUID + "Object"]: finalObj,
        [formUID + "Form"]: form,
      })
      moduleObjRef.current = finalObj;
      setModuleObj(finalObj);
    } else {
      let form = null;

      if (params?.is_default_values) {
        const data = { ...params };
        delete data.is_default_values;
        delete data.moduleName;
        form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data, false, globalData, setGlobalData, message, navigationFn, permissions));
      } else {
        form = fields(response, params?.viewOnly, moduleName, OpenModalCustom, values, false, globalData, setGlobalData, message, navigationFn);
      }

      if (values) {
        form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, values, false, globalData, setGlobalData, message, navigationFn, permissions));
      }

      setOpenForm({
        [formUID + "Object"]: {},
        [formUID + "Form"]: form,
      })

      const newObject = api.formToObject(form);
      moduleObjRef.current = newObject;
      setModuleObj(newObject);
    }

    setGlobalLoading(false);
  }

  async function processCreateData(moduleNameNew) {
    let responseOfCreateData = await api.getCreateData(moduleNameNew, params)
    const skuToProduct = responseOfCreateData.Items.reduce((acc, product) => {
      acc[product.sku] = product
      return acc
    }, {})

    const idToSku = responseOfCreateData.Items.reduce((acc, product) => {
      acc[product._id] = product.sku
      return acc
    }, {})

    responseOfCreateData.skuToProduct = skuToProduct
    responseOfCreateData.idToSku = idToSku

    if (["Invoices", "Quotations", "SalesOrders", "DeliveryOrders"].includes(moduleNameNew)) {
      setCreateDataObjectForSales(responseOfCreateData)
      setLastCreateDataObjectDateSales(new Date())
    } else if (["SalesReturns"].includes(moduleNameNew)) {
      setCreateDataObjectForSalesReturn(responseOfCreateData)
      setLastCreateDataObjectDateSalesReturn(new Date())
    } else if (["PurchaseOrders", "Bills"].includes(moduleNameNew)) {
      setCreateDataObjectForPurchase(responseOfCreateData)
      setLastCreateDataObjectDatePurchase(new Date())
    } else if (["PurchaseReturns"].includes(moduleNameNew)) {
      setCreateDataObjectForPurchaseReturn(responseOfCreateData)
      setLastCreateDataObjectDatePurchaseReturn(new Date())
    }

    return responseOfCreateData
  }

  async function saveFn(mainObject, stay_on_page = false, ignore_message = false, save_and_new = false) {
    try {
      setSaving(true);

      const form = api.objectToForm(mainObject, getOpenForms[formUID + "Form"]);
      let object = { ...mainObject, ...api.formToObject(form) };

      let res = null;

      object.location_id = globalData.location_id;

      if (params.isEdit == "true" || params.isEdit == true) {
        res = await api.update(moduleName, object, params.id);
      } else {
        res = await api.save(moduleName, object);
      }

      if (res.message) {
        if (!ignore_message) {
          if (Platform.OS === 'web') {
            message.success(res.message);
          } else {
            Alert.alert(res.message);
          }
        }

        setSaving(false);

        if (res.success) {
          if (moduleName == "Items" || moduleName == "Contacts" || moduleName == "Tables" || moduleName == "DocumentTemplates" || moduleName == "TaxGroups" || moduleName == "Tcs") {
            if (lastCreateDataObjectDateSales) {
              setTimeout(async () => {
                processCreateData("Invoices")
              }, 100);
            }
            if (lastCreateDataObjectDateSalesReturn) {
              setTimeout(async () => {
                processCreateData("SalesReturns")
              }, 100);
            }
            if (lastCreateDataObjectDatePurchase) {
              setTimeout(async () => {
                processCreateData("PurchaseOrders")
              }, 100);
            }
            if (lastCreateDataObjectDatePurchaseReturn) {
              setTimeout(async () => {
                processCreateData("PurchaseReturns")
              }, 100);
            }
          }

          if (params.afterSaveEvent) {
            DeviceEventEmitter.emit(params.afterSaveEvent, {
              _id: res.data._id
            });
          }

          if(save_and_new) {
            if (Platform.OS == 'web') {
              setOpenModal({ [moduleName]: null })
              deleteForm(formUID + "Form");
              deleteForm(formUID + "Object");
            } else {
              if (!params.afterSaveEvent) {
                DeviceEventEmitter.emit("reloadListing", {
                  _id: res.data._id
                });
                navigation.pop();
              } else {
                navigation.pop();
              }
            }

            // {
            //   isVisible: true,
            //   moduleName: route.name,
            //   afterSaveEvent: 'reloadListing',
            //   disableEdit: props.disableEdit,
            //   disableDelete: props.disableDelete,
            //   ...(props.default_values ? props.default_values : {})
            // }

            setTimeout(() => {
              setOpenModal({
                [moduleName]: {
                  isVisible: true,
                  moduleName: moduleName,
                  afterSaveEvent: 'reloadListing',
                  disableEdit: params?.disableEdit,
                  disableDelete: params?.disableDelete,
                  ...(params?.default_values ? params?.default_values : {})
                }
              })
            }, 500);
          }

          if (!stay_on_page) {
            if (Platform.OS == 'web') {
              setOpenModal({ [moduleName]: null })
              deleteForm(formUID + "Form");
              deleteForm(formUID + "Object");
            } else {
              if (!params.afterSaveEvent) {
                DeviceEventEmitter.emit("reloadListing", {
                  _id: res.data._id
                });
                navigation.pop();
              } else {
                navigation.pop();
              }
            }
          } else {
            //reload 
            params.id = res.data._id;
            params.isEdit = true;

            getModuleCreateData(null, {
              id: res.data._id,
              isEdit: true
            });

            setReloadUI(Math.random());
          }
          return res.data._id;
        }
      } else {
        console.log(res);
        if (Platform.OS === 'web') {
          message.error("Something went wrong!");
        } else {
          Alert.alert("Something went wrong!");
        }

        setSaving(false);
      }
    } catch (e) {
      console.log("error", e);
      if (Platform.OS === 'web') {
        message.error("Something went wrong!");
      } else {
        Alert.alert("Something went wrong!");
      }

      setSaving(false);
    }
  }

  async function deleteFn(mainObject) {
    async function actualDeleteFn() {
      try {
        const res = await api.delete(moduleName, mainObject._id);

        if (res.message) {
          if (Platform.OS === 'web') {
            message.success(res.message);
          } else {
            Alert.alert(res.message);
          }

          if (res.success) {
            if (params.afterSaveEvent) {
              DeviceEventEmitter.emit(params.afterSaveEvent, {
                deleted: true,
                id: mainObject._id
              });
            }

            setOpenModal({ [moduleName]: null })
            deleteForm(formUID + "Form");
            deleteForm(formUID + "Object");

            if (Platform.OS != 'web') {
              navigation.pop();
            }
          }
        } else {
          if (Platform.OS === 'web') {
            message.error("Something went wrong!");
          } else {
            Alert.alert("Something went wrong!");
          }
        }
      } catch (e) {
        if (Platform.OS === 'web') {
          message.error("Something went wrong!");
        } else {
          Alert.alert("Something went wrong!");
        }
      }
    }

    if (Platform.OS === 'web') {
      modal.confirm({
        title: 'Are you sure you want to delete?',
        content: 'This action cannot be undone.',
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          await actualDeleteFn()
        },
        onCancel: () => {
        }
      });
    } else {
      Alert.alert(
        "Are you sure you want to delete?",
        "This action cannot be undone.",
        [
          {
            text: "Yes",
            onPress: async () => {
              await actualDeleteFn()
            },
            style: "default"
          },
          {
            text: "No",
            onPress: () => { },
            style: "cancel"
          }
        ]
      );
    }
  }

  async function syncData() {
    console.log(syncClickCount);
    if (syncClickCount < 5) {
      setSyncClickCount(syncClickCount + 1);
      return;
    }

    try {
      console.log("syncing", moduleName, moduleObjRef.current._id);
      const res = await api.sync(moduleName, moduleObjRef.current._id);
      if (res.message) {
        if (Platform.OS === 'web') {
          message.success(res.message);
        } else {
          Alert.alert(res.message);
        }
      }
    } catch (e) {
      console.log(e);
      console.log("syncing", moduleName, moduleObjRef.current._id);
    } finally {
      setSyncClickCount(0);
    }
  }

  async function cancelFn() {
    if (params.afterSaveEvent) {
      DeviceEventEmitter.emit(params.afterSaveEvent, {
        deleted: false,
      });
    }

    if (Platform.OS == 'web') {
      setOpenModal({ [moduleName]: null })
    } else {

      navigation.pop();
    }
    deleteForm(formUID + "Form");
    deleteForm(formUID + "Object");
  }

  function reloadModuleData() {
    let object = { ...moduleObjRef.current };
    getModuleCreateData(object, true);
  }

  useEffect(() => {
    getModuleCreateData();
    return () => {
      DeviceEventEmitter.emit(params.afterSaveEvent, null);

      deleteForm(formUID + "Form");
      deleteForm(formUID + "Object");
      deleteForm(formUID + "Form" + "Preferences");
      deleteForm(formUID + "Object" + "Preferences");
    }
  }, [window?.location?.href, params?.viewOnly]);

  useEffect(() => {
    if (Platform.OS == 'web') {
      const Mousetrap = require('mousetrap');

      Mousetrap.bind('esc', () => {
        cancelFn()
      });

      Mousetrap.prototype.stopCallback = function (e) {
        const selectDropdowns = document.querySelectorAll('.ant-select-dropdown');
        const pickerDropdowns = document.querySelectorAll('.ant-picker-dropdown');

        let isAnyOpen = false;

        selectDropdowns.forEach(dropdown => {
          if (!dropdown.classList.contains('ant-select-dropdown-hidden')) {
            isAnyOpen = true;
          }
        });

        pickerDropdowns.forEach(dropdown => {
          if (!dropdown.classList.contains('ant-picker-dropdown-hidden')) {
            isAnyOpen = true;
          }
        });

        return isAnyOpen;
      }
    }

    return () => {
      if (Platform.OS == 'web') {
        const Mousetrap = require('mousetrap');
        Mousetrap.unbind('esc');
      }
    }
  }, []);

  const changeTab = (index) => {
    const tab = tabs[index];
    setActiveTab(tab);
  };

  const actionFunctions = (row) => {
    const actions = [
      {
        label: 'Edit',
        key: 'update',
        fn: ({ data, updateData, row, row_index }) => {
          if (Platform.OS === 'web') {
            setOpenModal({
              [moduleName]: {
                ...(params ? params : {}),
                isVisible: true,
                moduleName: moduleName,
                id: row._id,
                viewOnly: false,
                isEdit: true,
                afterSaveEvent: params.afterSaveEvent ? params.afterSaveEvent : 'reloadListing',
              }
            })
          } else {
            navigationFn('New', {
              ...(params ? params : {}),
              moduleName: moduleName,
              isEdit: true,
              viewOnly: false,
              id: row._id,
              goBackAfterSave: true
            });
          }
        }
      },
      {
        label: 'Clone',
        key: 'create',
        fn: ({ data, updateData, row, row_index }) => {
          if (Platform.OS === 'web') {
            setOpenModal({
              [moduleName]: {
                isVisible: true,
                moduleName: moduleName,
                id: row._id,
                afterSaveEvent: 'reloadListing',
              }
            })
          } else {
            navigationFn('New', {
              moduleName: moduleName,
              id: row._id,
              goBackAfterSave: true
            });
          }
        }
      },
      ...(customActions ? customActions(moduleName, navigationFn, route, row, setOpenModal, openCustomModal) : []),
      {
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          const deleteFn = async () => {
            try {
              const res = await api.delete(moduleName, row._id);

              if (res.message) {
                if (Platform.OS === 'web') {
                  message.success(res.message);
                } else {
                  Alert.alert(res.message);
                }

                if (res.success) {
                  getModuleData();
                }
              } else {
                if (Platform.OS === 'web') {
                  message.error("Something went wrong!");
                } else {
                  Alert.alert("Something went wrong!");
                }
              }
            } catch (e) {
              if (Platform.OS === 'web') {
                message.error("Something went wrong!");
              } else {
                Alert.alert("Something went wrong!");
              }
            }
          }
          if (Platform.OS === 'web') {
            modal.confirm({
              title: 'Are you sure you want to delete?',
              content: 'This action cannot be undone.',
              okText: 'Yes',
              cancelText: 'No',
              onOk: async () => {
                await deleteFn()
              },
              onCancel: () => {
              }
            });
          } else {
            Alert.alert(
              "Are you sure you want to delete?",
              "This action cannot be undone.",
              [
                {
                  text: "Yes",
                  onPress: async () => {
                    await deleteFn()
                  },
                  style: "default"
                },
                {
                  text: "No",
                  onPress: () => { },
                  style: "cancel"
                }
              ]
            );
          }
        }
      }
    ];

    let newActions = actions.filter((action) => {
      if (permissions[moduleName] && permissions[moduleName][action.key]) {
        return true;
      } else {
        return true;
      }
    });

    newActions = newActions.filter((action) => {
      if (row.disable_editing || params?.disableEdit) {
        if (action.key === 'update') {
          return false;
        } else {
          return true;
        }
      }

      if (row.disable_deleting || params?.disableDelete) {
        if (action.key === 'delete') {
          return false;
        } else {
          return true;
        }
      }

      return true;
    })

    return newActions;
  }

  const readableModuleName = pluralize.singular(params?.moduleName?.replace(/([A-Z])/g, ' $1').trim() || '');

  const HeaderExtra = <View row centerV>
    {params?.viewOnly != true && <View row>
      <View flex left marginR-10 marginT-10 style={{ zIndex: 2 }} w-100>
        <View flex w-100 row>
          {preferencesFields && Platform.OS == 'web' ? <TouchableOpacity onPress={async () => {
            const response = await api.getCreateData(moduleName)
            const data = await api.getPreferenceData(moduleName)
            let form = api.objectToForm(data, preferencesFields(response, false, moduleName, OpenModalCustom, data, false, globalData, setGlobalData, message, navigationFn, permissions))
            const object = api.formToObject(form)

            setModulePreObj(object)

            setOpenForm({
              [formUID + "Preferences" + "Object"]: object,
              [formUID + "Preferences" + "Form"]: form,
            })
            setShowPreferences(!showPreferences)
          }}>
            <View row>
              <Icon name="settings-2-outline" width={12} height={12} fill={'#979899'} />
              <Text style={{
                color: '#979899',
                fontFamily: 'SourceSansProSemiBold',
                fontSize: 12,
                letterSpacing: 0.3,
                lineHeight: 13,
                textTransform: 'uppercase',
                marginLeft: 5
              }}>Preferences</Text>
            </View>
          </TouchableOpacity> : null}
        </View>
      </View>
    </View>}

    {((permissions[moduleName] && permissions[moduleName]['update'] && (params?.viewOnly == true && !params?.hideEditButton)) && actionFunctions(moduleObj).findIndex((el) => el.key == 'update') >= 0) && <View row>
      <SecondaryButton style={{
        marginRight: 5,
        backgroundColor: "white"
      }} label={"Edit"} onPress={() => {
        actionFunctions(moduleObj)[actionFunctions(moduleObj).findIndex((el) => el.key == 'update')].fn({ data: moduleObj, updateData: setModuleObj, row: moduleObj, row_index: 0 })
      }} />

      {Platform.OS == 'web' && (params?.viewOnly == true && !params?.hideEditButton) && <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}>
        {actionFunctions ? (
          <div className="actions-container">
            {actionFunctions && actionFunctions(moduleObj).length >= 1 ? (
              <>
                {(actionFunctions && actionFunctions(moduleObj).length == 1 && actionFunctions(moduleObj)[0].label == "Delete") ? <>
                  <div style={{
                    cursor: 'pointer',
                    height: 40,
                    width: 40,
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }} onClick={() => {
                    actionFunctions(moduleObj)[0].fn({
                      data: moduleObj,
                      updateData: moduleObj,
                      row: moduleObj,
                      row_index: 0
                    })
                  }}>
                    {/* <Icon name='trash-2-outline' width={16} height={16} fill='#53535f' /> */}
                    <img src={"https://cdn-icons-png.flaticon.com/512/11847/11847093.png"} style={{ width: 20, height: 20 }} />
                  </div>
                </> :
                  <div className="dropdown">
                    <div className="pointer" onClick={(e) => e.stopPropagation()}>
                      <Dropdown
                        menu={{
                          items: actionFunctions(moduleObj)
                            .filter((action) => action.label != "Edit")
                            .map((action) => ({
                              label: action.label, onClick: () => action.fn({
                                data: moduleObj,
                                updateData: moduleObj,
                                row: moduleObj,
                                row_index: 0
                              })
                            }))
                        }}
                      >
                        <View left>
                          <Button avoidMinWidth borderRadius={4}
                            outline
                            outlineColor="#edf2f9"
                            style={{
                              boxShadow: '0 2px 0 rgba(0,0,0, 0.015)',
                              backgroundColor: 'white',
                              height: 40,
                            }}>
                            {/* more-vertical-outline */}
                            <Icon name='more-vertical-outline' width={16} height={16} fill='#53535f' />
                          </Button>
                        </View>
                      </Dropdown>
                    </div>
                  </div>}
              </>) : null}
          </div>
        ) : null}
      </div>}
    </View>}

    {Platform.OS == 'web' && <TouchableOpacity style={CustomViewHeader ? {
      backgroundColor: 'white',
      padding: 7,
      borderRadius: 5,
    } : { marginRight: 5 }} onPress={() => {
      goBack()
    }}>
      <Icon name="close-outline" width={20} height={20} fill={'#5670a1'} />
    </TouchableOpacity>}
  </View>;

  return (
    <View useSafeArea key={reloadUI}>
      <Card style={Platform.OS == 'web' ? {
        overflow: 'hidden',
        height: Dimensions.get('window').height,
        borderRadius: 0,
      } : {}}>

        {props.hideHeader ? <></> : ((CustomViewHeader && (params?.viewOnly == "true" || params?.viewOnly == true)) ? <CustomViewHeader HeaderExtra={HeaderExtra} connectedObject={moduleObj} /> : <View padding-15 paddingB-10 style={{ backgroundColor: '#0a152f', borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
          <View spread row centerV>

            {Platform.OS != 'web' && (!params?.viewOnly ? <Text color="white" h1>{(params?.isEdit == true || params?.isEdit == "true") ? "Edit " + readableModuleName : "New " + readableModuleName}</Text> : <Text h1>{readableModuleName}</Text>)}

            {Platform.OS == 'web' && <View row centerV>
              {Platform.OS == 'web' && <DangerButton
                style={{
                  height: 32,
                  marginRight: 12
                }}
                onPress={() => {
                  goBack()
                }}>
                <View row centerV>
                  <Icon name="arrow-back-outline" width={16} height={16} fill={"#b60505"} />
                  <Text marginL-5 style={{
                    color: '#b60505',
                    fontFamily: 'SourceSansProSemiBold'
                  }}>
                    Go Back
                  </Text>
                </View>
              </DangerButton>}

              ({!params?.viewOnly ? <Text color="white" h1>{(params?.isEdit == true || params?.isEdit == "true") ? "Edit " + readableModuleName : "New " + readableModuleName}</Text> : <Text color="white" h1>{readableModuleName}</Text>})
            </View>}

            {HeaderExtra}
          </View>
        </View>)
        }

        {
          preferencesFields ? <CustomModal drawer
            width="80%"
            visible={showPreferences} onClose={
              () => {
                setShowPreferences(false);
              }
            }>
              <View marginR-20>
                <DynamicForm
                formUID={formUID + "Preferences"}
                connectedObject={modulePrefObj}
                connectedObjectRef={modulePrefObjRef}
                setConnectedObject={setModulePreObj}
                getOpenForms={getOpenForms}
                setOpenForm={setOpenForm}
                preferences={true}
                saveFn={async (mainObject) => {
                  setSaving(true);

                  const form = api.objectToForm(mainObject, getOpenForms[formUID + "Preferences" + "Form"]);
                  let object = { ...mainObject, ...api.formToObject(form) };

                  let res = await api.savePreferenceData(moduleName, object);

                  if (res.message) {
                    if (Platform.OS === 'web') {
                      message.success(res.message);
                    } else {
                      Alert.alert(res.message);
                    }

                    setSaving(false);

                    if (res.success) {
                      setShowPreferences(false);
                      reloadModuleData();
                    }
                  } else {
                    Alert.alert("Something went wrong!");
                    if (window?.alert) {
                      window.alert("Something went wrong!");
                    }
                    setSaving(false);
                  }
                }}
              />
              </View>
            
          </CustomModal > : null}

        <View>
          {activeTab.label.includes("Details") && <View>
            <DynamicForm
              viewOnly={params?.viewOnly ? params?.viewOnly : false}
              formUID={formUID}
              connectedObject={moduleObj}
              moduleName={moduleName}
              connectedObjectRef={moduleObjRef}
              setConnectedObject={setModuleObj}
              getOpenForms={getOpenForms}
              setOpenForm={setOpenForm}
              dataTabs={dataTabs}
              saveFn={saveFn}
              cancelFn={cancelFn}
              deleteFn={params?.isEdit ? deleteFn : null}
              ExtraSaveComponent={ExtraSaveComponent}
              customHeaderHeight={customHeaderHeight}
              hideHeader={props.hideHeader}
              show_save_and_new={true}
            />
          </View>
          }
          {activeTab.label.includes("Comments") && <CommentsSection />}
          {activeTab.label.includes("Documents") && <View>
            <Text>Documents</Text>
          </View>}

          {!(["Details", "Comments", "Documents"].includes(activeTab.label)) && <View>
            <activeTab.component moduleName={moduleName} obj={moduleObj} />
          </View>}

        </View>
      </Card >
    </View >
  )
})

export default FormScreen

const $container = {
  flex: 1,
}